<template lang="pug">
  .vue-pouvoirs(:class="{ success }")
    menu-button
    img.w-100.p-absolute.bg(src="@/assets/pouvoirs/fond.jpg" srcset="@/assets/pouvoirs/fond@2x.jpg 2x")
    svg.p-absolute(ref="svg" width='100%' height='100%' viewbox='0 0 1920 1080' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;')
      g#boutons(transform='matrix(1,0,0,0.9,0,0)')
        g#bouton_21(transform='matrix(0.904491,0,0,0.837018,1534.29,899.798)' @click="select(21)" :class="{ selected: isSelected(21) }")
          path(d='M409.865,1.043L-20.244,-0.596L-45.532,343.943L409.865,338.171L409.865,1.043Z')
        g#bouton_20(transform='matrix(0.904491,0,0,0.837018,774.368,899.798)' @click="select(20)" :class="{ selected: isSelected(20) }")
          path(d='M366.12,0.719L-97.842,1.043L-27.594,338.171L409.865,338.171L366.12,0.719Z')
        g#bouton_19(transform='matrix(0.904491,0,0,0.837018,394.409,899.798)' @click="select(19)" :class="{ selected: isSelected(19) }")
          path(d='M310.659,1.043L1.645,1.043L1.645,338.171L378.695,338.171L310.659,1.043Z')
        g#bouton_18(transform='matrix(0.904491,0,0,0.837018,14.4504,899.798)' @click="select(18)" :class="{ selected: isSelected(18) }")
          path(d='M409.865,1.043L252.614,1.043L199.148,38.419L155.752,-0.259L1.645,1.043L1.645,338.171L409.865,338.171L409.865,1.043Z')
        g#bouton_17(transform='matrix(0.904491,0,0,0.837018,1534.29,605.22)' @click="select(17)" :class="{ selected: isSelected(17) }")
          path(d='M409.865,-185.49L1.645,1.043L-15.689,338.914L409.865,338.171L409.865,-185.49Z')
        g#bouton_16(transform='matrix(0.904491,0,0,0.837018,1368.13,605.22)' @click="select(16)" :class="{ selected: isSelected(16) }")
          path(d='M171.198,1.043L-50.46,1.043L0.899,690.276L124.021,695.88L171.198,1.043Z')
        g#bouton_15(transform='matrix(0.904491,0,0,0.837018,1154.33,605.22)' @click="select(15)" :class="{ selected: isSelected(15) }")
          path(d='M171.198,1.043L-6.334,1.782L-58.298,119.258L-21.191,166.642L-58.298,212.06L1.645,690.028L222.557,690.195L171.198,1.043Z')
        g#bouton_14(transform='matrix(0.904491,0,0,0.837018,774.368,605.22)' @click="select(14)" :class="{ selected: isSelected(14) }")
          path(d='M400.525,2.4L-5.413,-104.06L-96.128,339.259L366.12,338.271L346.793,210.727L384.04,166.745L349.922,121.23L400.525,2.4Z')
        g#bouton_13(transform='matrix(0.904491,0,0,0.837018,394.409,605.22)' @click="select(13)" :class="{ selected: isSelected(13) }")
          path(d='M401.16,-104.484L5.701,29.061L1.645,338.171L310.659,338.171L401.16,-104.484Z')
        g#bouton_12(transform='matrix(0.904491,0,0,0.837018,14.4504,605.22)' @click="select(12)" :class="{ selected: isSelected(12) }")
          path(d='M411.849,28.339L1.645,1.043L1.645,338.171L158.65,338.171L200.176,374.124L251.993,338.171L409.865,338.171L411.849,28.339Z')
        g#bouton_11(transform='matrix(0.904491,0,0,0.837018,1534.29,310.643)' @click="select(11)" :class="{ selected: isSelected(11) }")
          path(d='M409.865,1.043L-121.439,47.038L1.645,338.171L409.865,151.638L409.865,1.043Z')
        g#bouton_10(transform='matrix(0.904491,0,0,0.837018,1154.33,310.643)' @click="select(10)" :class="{ selected: isSelected(10) }")
          path(d='M286.094,52.239L1.645,169.897L1.645,338.171L406.389,338.505L286.094,52.239Z')
        g#bouton_09(transform='matrix(0.904491,0,0,0.837018,774.368,310.643)' @click="select(9)" :class="{ selected: isSelected(9) }")
          path(d='M409.865,-218.029L46.784,-27.247L-2.249,233.176L410.778,338.169L409.865,-218.029Z')
        g#bouton_08(transform='matrix(0.904491,1.2326e-32,0,0.837018,394.409,310.643)' @click="select(8)" :class="{ selected: isSelected(8) }")
          path(d='M450.645,-23.443L22.836,-1.085L5.701,363.41L404.337,228.221L450.645,-23.443Z')
        g#bouton_07(transform='matrix(-0.904491,1.10768e-16,-1.02505e-16,-0.837018,385.102,594.571)' @click="select(7)" :class="{ selected: isSelected(7) }")
          path(d='M246.233,-8.162L-1.016,-23.214L-17.402,380.336L165.641,362.124L246.233,-8.162Z')
        g#bouton_06(@click="select(6)" :class="{ selected: isSelected(6) }")
          path(d='M224.308,294.016L15.938,311.515L15.938,593L151.476,600.234L224.308,294.016Z')
        g#bouton_05(transform='matrix(0.904491,0,0,0.837018,1534.29,16.065)' @click="select(5)" :class="{ selected: isSelected(5) }")
          path(d='M409.865,1.043L1.645,1.043L-118.741,385.168L409.865,338.171L409.865,1.043Z')
        g#bouton_04(transform='matrix(0.904491,0,0,0.837018,1154.33,16.065)' @click="select(4)" :class="{ selected: isSelected(4) }")
          path(d='M409.865,1.043L1.645,1.043L1.645,507.025L286.199,389.324L409.865,1.043Z')
        g#bouton_03(transform='matrix(0.904491,0,0,0.837018,774.368,16.065)' @click="select(3)" :class="{ selected: isSelected(3) }")
          path(d='M409.865,1.043L1.645,1.043L41.434,310.137L409.865,119.099L409.865,1.043Z')
        g#bouton_02(transform='matrix(0.904491,0,0,0.837018,394.409,16.065)' @click="select(2)" :class="{ selected: isSelected(2) }")
          path(d='M409.865,1.043L1.645,1.043L22.836,334.848L450.645,310.929L409.865,1.043Z')
        g#bouton_01(transform='matrix(0.904491,0,0,0.837018,14.4504,16.065)' @click="select(1)" :class="{ selected: isSelected(1) }")
          path(d='M409.865,1.043L1.645,1.043L1.645,338.171L427.192,294.127L409.865,1.043Z')
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'

/* Selection => Route name */
const SELECTION_ROUTES = {
  '1-15-17': 'Carson',
  '5-11-18': 'Lavoisier',
  '10-12-16': 'DePossel',
  '7-8-20': 'BabbageLovelace',
  '6-9-21': 'Knight',
  '2-14-19': 'Appert',
  '3-4-13': 'Vaucanson'
}

export default {
  name: 'VuePouvoirs',

  components: {
    MenuButton
  },

  computed: {
    navId () {
      return this.selection.join('-')
    }
  },

  mounted () {
    this.$refs.svg.setAttribute('viewBox', '0 0 1920 1080')
  },

  data () {
    return {
      selection: [],
      success: false
    }
  },

  methods: {
    select (n) {
      this.$sound('tick')
      const p = this.selection.indexOf(n)
      if (p !== -1) {
        this.selection.splice(p, 1)
      } else {
        this.selection.push(n)
      }
      this.selection.sort((a, b) => parseInt(a) - parseInt(b))
      const route = SELECTION_ROUTES[this.navId]
      if (route) {
        this.$sound('validate')
        this.success = true
        setTimeout(() => this.$router.push({ name: route }), 1000)
      }
    },

    isSelected (n) {
      return this.selection.indexOf(n) !== -1
    }
  }
}
</script>

<style lang="less">
.vue-pouvoirs {
  #menu-button {
    top: -18px;
    right: -18px;
  }
  svg {
    path {
      fill: white;
      fill-opacity: 0;
      transition: fill 250ms ease-in-out, fill-opacity 250ms ease-in-out;
    }
    .selected path {
      fill: white;
      stroke: black;
      stroke-width: 10px;
    }
  }

  &.success {
    svg {
      g.selected path {
        fill: rgb(115, 255, 0) ! important;
        fill-opacity: 0.5 ! important;
        stroke: rgb(115, 255, 0) ! important;
      }
      g:not(.selected) path {
        fill: black;
        fill-opacity: 0.75;
      }
    }
  }
}
</style>
